import { Component } from 'react';
import PropTypes from 'prop-types';
import { AndroidOutlined, CalendarOutlined, PhoneOutlined } from '@ant-design/icons';
import { Modal, Tabs } from 'antd';
import Markdown from 'react-markdown';
import CallDashboard from './CallDashboard';
import TucuxiConfig from './TucuxiConfig.json';
import './GroupPortal.css';

export default class GroupPortal extends Component {

  constructor(props) {
    super(props);

    const group = TucuxiConfig.groups[props.groupName];
    const botName = group && group.assistant && group.assistant.botName;
    const tabItems = [];
    let tabId = 1;
    if (group && group.callDashboard && group.callDashboard.vapiCampaign) {
        tabItems.push({
            key: '' + tabId++,
            label: 'Call Dashboard',
            children: <CallDashboard groupName={group.callDashboard.vapiCampaign} roleName={props.roleName} />,
            icon: <PhoneOutlined />
        });
    }
    if (botName) {
        tabItems.push({
            key: '' + tabId++,
            label: 'AI Assistant',
            children: <>
                    <iframe 
                        title='Tucuxi Assistant'
                        src={`https://embed.cody.bot/${TucuxiConfig.bot[botName]}`}
                        style={{border: '0px'}}
                        name="codyai"
                        scrolling="no"
                        frame-border="1"
                        margin-height="0"
                        margin-width="0"
                        height="600px"
                        max-width="1200px"
                        width='90%'
                        allowFullScreen>
                    </iframe>
                </>,
            icon: <AndroidOutlined />
        });
    }

    if (group.calendarSrc) {
        tabItems.push({
            key: '' + tabId++,
            label: 'Calendar',
            children: <>
                <iframe
                    title='Calendar'
                    src={group.calendarSrc}
                    style={{border: 'solid 1px #777'}}
                    width="1200px"
                    height="600px"
                    frameborder="0"
                    scrolling="no">
                </iframe>
            </>,
            icon: <CalendarOutlined />
        });
    }

    const showWelcome = group.name === 'Guest';

    this.state = {
        botName,
        defaultTab: '1',
        group,
        tabItems,
        showWelcome,
        welcome:  undefined,
    }
  }

  componentDidMount() {
    if (this.state.showWelcome) {
        this.loadWelcome();
    }
  }

  loadWelcome() {
    fetch('guest_portal_welcome.md')
      .then(r => r.text())
      .then(welcome  => {
        this.setState({ welcome });
      });
  }

  render() {
    return (<>
        <div className='group-portal-tabs-wrapper'>
            <Tabs
                defaultActiveKey={'' + this.state.defaultTab}
                items={this.state.tabItems}
            />
        </div>
        <Modal
            footer={null}
            title={null}
            open={this.state.showWelcome}
            onOk={() => this.setState({ showWelcome: null })}
            onCancel={() => this.setState({ showWelcome: null })}
        >
            <Markdown>
                {this.state.welcome}
            </Markdown>
        </Modal>
        </>
    );
  }
}

GroupPortal.defaultProps = {
    groupName: 'Empty',
    roleName: 'none',
};

GroupPortal.propTypes = {
    groupName: PropTypes.string.isRequired,
    roleName: PropTypes.string.isRequired,
}