import { Routes, Route, Outlet } from "react-router-dom";
import Tucuxi from './components/Tucuxi';
// import ClientIntake from './components/ClientIntake';
import IncomingCalls from './components/IncomingCalls';
import CodyConversations from "./components/CodyConversations";
import '@blueprintjs/core/lib/css/blueprint.css';
import './App.css';
// import BrousseauAndLee from "./components/BrousseauAndLee";

export default function AppTest() {
  return (
    <div>
      {/* Routes nest inside one another. Nested route paths build upon
            parent route paths, and nested route elements render inside
            parent route elements. See the note about <Outlet> below. */}
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Tucuxi />} />
          <Route path="associate" element={<Associate />} />
          {/* <Route path="client-intake" element={<Intake />} /> */}
          <Route path="incoming-calls" element={<Incoming />} />
          <Route path="conversations" element={<Conversations />} />
          {/* <Route path="brousseau-and-lee" element={<BAndL />} /> */}

          {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
          <Route path="*" element={<Tucuxi />} />
        </Route>
      </Routes>
    </div>
  );
}

function Layout() {
  return (
    <div>
      {/* A "layout route" is a good place to put markup you want to
          share across all the pages on your site, like navigation. */}
      {/* <nav>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/tucu">AI Associate</Link>
          </li>
          <li>
            <Link to="/client-intake">Client Intake</Link>
          </li>
          <li>
            <Link to="/nothing-here">Nothing Here</Link>
          </li>
        </ul>
      </nav>

      <hr /> */}

      {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
      <Outlet />
    </div>
  );
}

function Associate() {
  return (
    <Tucuxi />
  );
}

// function Intake() {
//   return (
//     <ClientIntake />
//   );
// }

function Incoming() {
  return (
    <IncomingCalls />
  );
}

// function BAndL() {
//   return (
//     <BrousseauAndLee />
//   );
// }

function Conversations() {
  return (
    <CodyConversations />
  );
}

// function NoMatch() {
//   return (
//     <div>
//       <h2>Nothing to see here!</h2>
//       <p>
//         <Link to="/">Go to the home page</Link>
//       </p>
//     </div>
//   );
// }