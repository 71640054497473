import { Component } from 'react';
import { Button, Form, Input, Modal, Result } from 'antd';
import { CREATE, LOGIN, LoginDialog } from './LoginDialog'
import { Cognito } from '../services/Cognito';
import Markdown from 'react-markdown';
import GroupPortal from './GroupPortal';
import TucuxiConfig from './TucuxiConfig.json';
import './Tucuxi.css';

export default class Tucuxi extends Component {

  constructor(props) {
    super(props);

    this.state = {
      codePrompt: null,
      loginAction: LOGIN,
      loginError: null,
      showLogin: false,
      IdToken: null,
      challenge: null,
      session: null,
      title: null,
      text: null,
      fullName: null,
      emailAddress: null,
      company: null,
      signUpFormSubmitted: false,
    }

    this.cody = undefined;
  }

  componentDidMount() {
    this.hideCodyWrapper(true);
  }

  hideCodyWrapper(hide) {
    this.cody = document.getElementById('cody-wrapper');
    if (this.cody) {
      this.cody.style['display'] = hide ? 'none' : 'block';
    }
  }

  handleLoginButtonPressed() {
    const userName = Cognito.getUser().userName;
    if (userName) {
      console.log('User ' + userName + ' is already logged-in.');
    } else {
      this.setState({ showLogin: true, loginAction: LOGIN, loginError: null })
    }   
  }

  handleLogoutButtonPressed() {
    Cognito.logout();
    this.setState({ IdToken: null });
  }

  handleSignUpFormSubmitted(e) {
    const formData = {
      fullName: this.state.fullName,
      emailAddress: this.state.emailAddress,
      company: this.state.company,
    };

    console.log('Sign-Up form submitted: ', formData);
    this.setState({ signUpFormSubmitted: true });

    // Below is supposed to help netlify identify form submissions:
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: this.encodeFormData({ "form-name": "contact", ...formData })
    })
      .then(() => console.log("contact form sumbitted"))
      .catch(err => console.error(err));

    e.preventDefault();
  }

  encodeFormData(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  }

  doCloseLoginDialog(token) {
    this.setState({
      codePrompt: null,
      loginError: null,
      showLogin: false,
      IdToken: token,
      challenge: null,
      session: null,
    });
  }

  doGetUserInfo(accessToken) {
    Cognito.getUserInfo(accessToken)
      .then(res => {
        console.log('doGetUserInfo response:', res);
      })
      .catch(err => {
        console.error('doGetUserInfo error:', err);
      });
  }
  
  doSubmitLogin(req) {
    Cognito.login(req.username, req.password)
      .then(res => {
        if (res) {
          if (res.AccessToken) {
            // this.doGetUserInfo(res.AccessToken);
          }
          if (res.IdToken) {
            this.doCloseLoginDialog(res.IdToken);
          } else if (res.ChallengeName) {
            this.setState({
              loginError: 'New password required',
              challenge: res.ChallengeName,
              session: res.Session,
            });
          } else {
            this.setState({
              loginError: 'Unknown response',
            });
          }
        } else {
          this.setState({
            loginError: 'Login error occurred',
          });
        }
      })
      .catch(err => {
        console.log('Login error: ', err);
        this.setState({
          loginError: err.message,
        });
    });
  }

  doSubmitChallengeResponse(req) {
    Cognito.respondToChallenge(this.state.challenge, this.state.session, req.username, req.password)
      .then(res => {
        console.log('Result: ', res);
        if (res && res.IdToken) {
          this.doCloseLoginDialog(res.IdToken);
        } else {
          this.setState({
            loginError: 'Unknown response',
          });
        }
      })
      .catch(err => {
        console.log('Error: ', err);
        this.setState({
          loginError: err.message,
        });
      });
  }

  doSubmitSignup(req) {
    Cognito.create(req.username, req.password)
      .then(res => {
        console.log('Result: ', res);
        if (res && res.IdToken) {
          this.doCloseLoginDialog(res.IdToken);
        } else {
          const destination = res.CodeDeliveryDetails ? res.CodeDeliveryDetails.Destination : 'unknown';
          this.setState({
            codePrompt: 'Enter verification code sent to "' + destination + '"',
            loginError: null,
          });
        }
      })
      .catch(err => {
        console.log('Error: ', err);
        this.setState({
          loginError: err.message,
        });
      });
  }

  doSubmitReset(req) {
    Cognito.resetPassword(req.username)
      .then(res => {
        console.log('Result: ', res);
        if (res && res.IdToken) {
          this.doCloseLoginDialog(res.IdToken);
        } else {
          const destination = res.CodeDeliveryDetails ? res.CodeDeliveryDetails.Destination : 'unknown';
          this.setState({
            codePrompt: 'Enter verification code sent to "' + destination + '"',
            loginError: null,
          });
        }
      })
      .catch(err => {
        console.log('Error: ', err);
        this.setState({
          loginError: err.message,
        });
      });
  }

  doVerifySignUpCode(req) {
    const result = Cognito.confirmSignUp(req.username, req.code);
    console.log('doVerifySignUpCode result:', result);
    if (result && typeof result === 'object') {
      result.then(r => {
        if (typeof r === 'string' && r.indexOf('failed') >= 0) {
          this.setState({ loginError: r });
        } else {
          this.setState({ loginError: null, codePrompt: null, loginAction: LOGIN });
        }
      })
      .catch(err => {
        console.log('Error: ', err);
        this.setState({
          loginError: err.message,
        });
      });
    }
  }

  doVerifyResetCode(req) {
    const result = Cognito.confirmReset(req.username, req.password, req.code);
    console.log('doVerifyResetCode result:', result);
    if (result && typeof result === 'object') {
      result.then(r => {
        if (typeof r === 'string' && r.indexOf('failed') >= 0) {
          this.setState({ loginError: r });
        } else {
          this.setState({ loginError: null, codePrompt: null, loginAction: LOGIN  });
        }
      })
      .catch(err => {
        console.log('Error: ', err);
        this.setState({
          loginError: err.message,
        });
      });
    }
  }

  doSignUp() {
    this.setState({ showLogin: true, loginAction: CREATE });
  }

  renderLogin() {
    return (
      <LoginDialog
        allowSignUp={TucuxiConfig.allowSignUp}
        action={this.state.loginAction}
        changeAction={loginAction => this.setState({ loginError: null, codePrompt: null, loginAction })}
        challenge={this.state.challenge}
        error={this.state.loginError}
        isOpen={true}
        onCancel={() => this.doCloseLoginDialog()}
        onClose={() => this.doCloseLoginDialog()}
        onChallengeResponse={req => this.doSubmitChallengeResponse(req)}
        onLogin={req => this.doSubmitLogin(req)}
        onReset={req => this.doSubmitReset(req)}
        onResetVerification={req => this.doVerifyResetCode(req)}
        onSignUp={req => this.doSubmitSignup(req)}
        onSignUpVerification={req => this.doVerifySignUpCode(req)}
        promptForVerificationCode={this.state.codePrompt}
        showTerms={() => this.showText(TucuxiConfig.termsFile)}
        showPrivacy={() => this.showText(TucuxiConfig.privacyFile)}
      />
    );
  }

  renderSignUpForm() {
    return (
      <Form name='contact'
        layout='vertical'
        requiredMark='optional'
        size='large'
        style={{
          maxWidth: 600,
          minWidth: 300
        }}
      >
        {/* A little help for the Netlify post-processing bots */}
        <input type="hidden" name="form-name" value="contact" />
        <Form.Item label="Full Name" required>
          <Input
            name='fullName'
            onChange={e => this.setState({ fullName: e.target.value })}
            placeholder="your full name"
            value={this.state.fullName}
          />
        </Form.Item>
        <Form.Item label="Email Address" required>
          <Input
            name='emailAddress'
            onChange={e => this.setState({ emailAddress: e.target.value })}
            placeholder="your email address"
            type='email'
            value={this.state.emailAddress}
          />
            </Form.Item>
        <Form.Item label="Company">
          <Input
            name='company'
            onChange={e => this.setState({ company: e.target.value })}
            placeholder="your company affiliation"
            value={this.state.company}
          />
        </Form.Item>
        <Form.Item>
          <Button className='signup-form-submit-button'
            disabled={!(this.state.fullName && this.state.emailAddress)}
            onClick={e => this.handleSignUpFormSubmitted(e)}
            type="primary"
          >
            Keep me informed!
          </Button>
          <div className='tucuxi-form-terms-agreement'>
            By clicking "Keep me informed!" you agree to our&nbsp;
            {/* eslint-disable-next-line */}
            <a href='javascript:void(0)' onClick={() => this.showText(TucuxiConfig.termsFile)}>Terms</a> and&nbsp;
            {/* eslint-disable-next-line */}
            <a href='javascript:void(0)' onClick={() => this.showText(TucuxiConfig.privacyFile)}>Privacy Policy</a>.
          </div>
        </Form.Item>
      </Form>
    );
  }

  renderFormSubmitAcknowledgement() {
    return (
      <div className='form-submit-acknowledgement'>
        <Result
          status="success"
          title="Congratulations, you're signed-up!"
          subTitle="We'll keep you informed of updates and opportunities to try the latest Tucuxi products."
        />
      </div>
    );
  }

  renderPromotions() {
    const promotions = [];
    if (!Cognito.getUser().userName) {
      TucuxiConfig.content.promotions.forEach(p => {
        promotions.push(
          <div className='tucuxi-page-promotion'>
            <span>{p.text} <a href={p.click_target} target='_blank' rel='noreferrer'>{p.click_text}</a>.</span>
          </div>
        );
      });
    }

    return promotions;
  }

  renderUnauthenticatedContent() {
    const TucuxiContent = TucuxiConfig.content;
    return (<>
        <div className="tucuxi-home-header">
            From Intake to Work Product, our applications help you grow your revenue.
        </div>
        <div className="tucuxi-home-product-header">
          State of the Art AI Receptionists and Assistants
        </div>
        <div className="tucuxi-home-guest-sign-up">
          <div className="tucuxi-home-guest-sign-up-text">Create a free guest account and try our products now!</div>
          <Button type="primary" onClick={() => this.doSignUp()}><h3>Create a Free Guest Account</h3></Button>
        </div>
        <div className="tucuxi-home-product-section">
            <div className="tucuxi-home-product-content">
                <div className="tucuxi-home-product-name">
                  <a href='/calldonna' target='_blank'>CallDonna</a>
                </div>
                <div className="tucuxi-home-product-description">
                  <h3>Always there for your customers</h3>
                  CallDonna delivers AI powered Intake over phone, chat or other channels.
                  Available 24/7 to capture, analyze and qualify your inbound leads and assist
                  in efficiently generating relevant work product.
                  <br />CallDonna — <i>From Reception to Revenue</i>
                  <p className='learn-more-link'><a className='learn-more-link' href='/calldonna' target='_blank'>Learn more</a></p>
                </div>
            </div>
        </div>
        <div className="tucuxi-home-product-section">
            <div className="tucuxi-home-product-content">
                <div className="tucuxi-home-product-name">
                  <a href='/sota' target='_blank'>SOTA AI</a>
                </div>
                <div className="tucuxi-home-product-description">
                  <h3>State of the art productivity power-up</h3>
                  For use either stand-alone or in coordination with our AI Reception service CallDonna,
                  we offer a state of the art AI Assistant capable of handing a wide variety of administrative and drafting tasks.
                  <br /><br />
                  We combine the AI Assistant with a free helper assistant "Tucu" who helps convert your
                  plain English commands into prompts that will generate the best possible results from the AI Assistant.
                  <p className='learn-more-link'><a className='learn-more-link' href='/sota' target='_blank'>Learn more</a></p>
                </div>
            </div>
        </div>
        <div className="tucuxi-home-product-section">
            <div className="tucuxi-home-product-content">
                <div className="tucuxi-home-product-name">
                  <a href='/tucuwisdom' target='_blank'>Wisdom Management</a>
                </div>
                <div className="tucuxi-home-product-description">
                  <h3>Always there for your team</h3>
                  Our solutions collect and manage your team’s learnings,
                  so that everyone in your company can leverage the team's
                  collective wisdom to deliver efficient and consistent service,
                  increasing customer satisfaction,
                  lowering churn and increasing revenue.
                  <br /><br />
                  Generate opinions and memos in record time, leverage past work to
                  generate new contracts, briefs or other materials. Generate
                  presentations in a fraction of the time. Take advantage of AI and
                  your team's knowledge to give your team super-powers.
                  <p className='learn-more-link'><a className='learn-more-link' href='/tucuwisdom' target='_blank'>Learn more</a></p>
                </div>
            </div>
        </div>
        <div className='tucuxi-page-lead-capture'>
                <h1>{TucuxiContent.intro.leadIn}</h1>
                {/* <h3>{TucuxiContent.intro.leadCapture}</h3> */}
                {this.state.signUpFormSubmitted ? this.renderFormSubmitAcknowledgement() : this.renderSignUpForm()}
        </div>
    </>
    );
  }

  getGroupAndRole(loggedInUser) {
    let groupName = null;
    let roleName = 'none';
    if (loggedInUser && loggedInUser.userName) {
      if (loggedInUser.groups) {
        loggedInUser.groups.forEach(name => {
          const group = TucuxiConfig.groups[name];
          if (group.type === 'org') {
            groupName = name;
          } else if (group.type === 'role') {
            roleName = name;
          }
        });
      }

      if (!groupName) {
        groupName = 'Guest';
      }
    }

    return { groupName, roleName };
  }

  groupHasAiAssistant(loggedInUser) {
    let hasAiAssistant = false;
    if (loggedInUser && loggedInUser.userName) {
      const groupNames = loggedInUser.groups || ['Guest'];
      groupNames.forEach(name => {
        const group = TucuxiConfig.groups[name];
        if (group.type === 'org' && group.assistant) {
          hasAiAssistant = true;
        }
      });
    }

    return hasAiAssistant;
  }

  showText(filename, title) {
    fetch(filename)
      .then(r => r.text())
      .then(text  => {
        this.setState({ title, text });
      });
  }

  render(params) {
    const loggedInUser = Cognito.getUser();
    const {groupName, roleName} = this.getGroupAndRole(loggedInUser);
    this.hideCodyWrapper(!this.groupHasAiAssistant(loggedInUser));
    return (
      <div className="App">
        <div className='tucuxi-app-header'>
          <a href='/'>
            <img 
                id='tucuxi-app-header-logo'
                src='images/cropped-tucuxi_color_logo-2-transparent-300x133.png'
                alt='tucuxi.ai logo'
            />
          </a>
          {TucuxiConfig.renderLoginButton && <div className='tucuxi-app-header-bar'>
            <div className='header-left-content'></div>
            <div className='header-center-content'>
              {groupName && TucuxiConfig.groups[groupName] ? TucuxiConfig.groups[groupName].name + ' Portal' : ''}
            </div>
            <div className='header-right-content'>
              <div className='login-button-container'>
                { loggedInUser.userName
                  ? <Button className='tucuxi-app-login-button' type="primary" onClick={() => this.handleLogoutButtonPressed()}>Log Out</Button>
                  : <Button className='tucuxi-app-login-button' type="primary" onClick={() => this.handleLoginButtonPressed()}>Log In</Button>
                }
                <div className='header-logged-in-user'>{loggedInUser.userName || 'just visiting'}</div>
              </div>
              </div>
          </div>}
        </div>
        <div className='tucuxi-page-content'>
          <div className='tucuxi-page-content-primary'>
            {groupName ? <GroupPortal groupName={groupName} roleName={roleName} /> : loggedInUser.userName ? <GroupPortal groupName='Guest' /> : this.renderUnauthenticatedContent()}
          </div>
          <div className='tucuxi-page-content-secondary'>
            {/* {this.renderPromotions()} */}
          </div>
        </div>
        {this.state.showLogin && this.renderLogin()}
        <div id='footer' className='tucuxi-app-footer'>
          <div className='tucuxi-terms-privacy-copyright'>
            {/* eslint-disable-next-line */}
            <a href='javascript:void(0)' onClick={() => this.showText(TucuxiConfig.termsFile)}>Terms</a>  |
            {/* eslint-disable-next-line */}
            <a href='javascript:void(0)' onClick={() => this.showText(TucuxiConfig.privacyFile)}>Privacy Policy</a>
            ©2024 Tucuxi, Inc.
          </div>
        </div>
        <Modal
            footer={null}
            title={this.state.title}
            open={this.state.text}
            onOk={() => this.setState({ title: null, text: null })}
            onCancel={() => this.setState({ title: null, text: null })}
        >
            <Markdown>{this.state.text}</Markdown>
        </Modal>
      </div>
    );
  }
}
