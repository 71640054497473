import { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, DatePicker, InputNumber, Modal, Popconfirm, Table, Tooltip } from 'antd';
import { QuestionCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import TucuxiConfig from './TucuxiConfig.json';
import { Vapi } from '../services/Vapi'
import './CallDashboard.css';

export default class CallDashboard extends Component {

  constructor(props) {
    super(props);

    const group = TucuxiConfig.groups[props.groupName] || {};
    const {createdAtGe, createdAtLe, limit} = (group && group.callDashboard) || {};
    const vapiCampaign = TucuxiConfig.vapi.campaigns[props.groupName];
    const queryParameters = vapiCampaign ? {
        assistantId: vapiCampaign && vapiCampaign.assistantId,
        createdAtGe,
        createdAtLe,
        limit,
    } : {}

    this.state = {
        callList: [],
        callToView: null,
        count: 0,
        group,
        queryParameters,
        vapiCampaign,
        waitingForResponse: false,        
    }
  }

  componentDidMount() {
    if (this.state.queryParameters.assistantId) {
        setTimeout(() => this.getCurrentCallList(), 1000);
    }
  }

  getCurrentCallList() {
    if (this.state.waitingForResponse) {
        return;
    }

    this.setState( { waitingForResponse: true },
        () => Vapi.getCalls(data => this.handleCallListReceived(data), err => this.handleError(err), this.state.queryParameters));
  }

  handleCallListReceived(data) {
    const callList = data ? data.filter(c => c.summary && c.recordingUrl) : [];
    if (data) {
        this.setState({ count: callList.length, callList, waitingForResponse: false }, () =>
            setTimeout(() => this.getCurrentCallList(), TucuxiConfig.vapi.callListPollPeriod));
    } else {
        console.log('Response contained no data!');
    }
  }

  handleError(err) {
    console.log(err);
  }

  onDeleteConfirmed(callId) {
    console.log('Deleting call data for call ID', callId)
    Vapi.deleteCall(data => {
        const updatedCallList = this.state.callList.filter(c => c.id !== callId);
        this.setState({ callList: updatedCallList });
    }, err => this.handleError(err), callId);
  }

  viewCallSummary(call) {
    this.setState({ callToView: call });
  }

  formatDate(dateStr) {
    const date = new Date(dateStr);
    return date.toLocaleString();
  }

  formatDuration(duration) {
    const minutes = Math.floor(duration / 60000);
    const seconds = Math.floor(duration / 1000) - minutes * 60;
    return (minutes ? (minutes < 10 ? '0' : '') + minutes : '00') + ':' + (seconds ? (seconds < 10 ? '0' : '') + seconds : '00');
  }

  extractScore(summary, searchString) {
    let urgency = 0;
    let startIndex = summary && summary.indexOf(searchString);
    if (startIndex > 0) {
        startIndex = startIndex + searchString.length;
        urgency = summary.substring(startIndex, summary.indexOf('/', startIndex));
    }

    return urgency;
  }

  renderCallTable(data) {
    const dataSource = [];
    if (data) {
        data.forEach(c => {
            const duration = new Date(c.endedAt) - new Date(c.startedAt);
            dataSource.push({
                key: c.id,
                date: c.startedAt,
                duration,
                type: c.type,
                summary: c.summary,
                recordingUrl: c.stereoRecordingUrl,
                endedReason: c.endedReason,
                status: c.status,
                urgency: this.extractScore(c.summary, 'Urgency Score: '),
                confidence: this.extractScore(c.summary, 'Confidence Score: '),
            });
        });
    }

    const columnDefs = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: text => this.formatDate(text),
            defaultSortOrder: 'descend',
            sorter: (a, b) => new Date(a.date) - new Date(b.date)
        },
        {
            title: 'Urgency Score',
            dataIndex: 'urgency',
            key: 'urgency',
            // defaultSortOrder: 'descend',
            sorter: (a, b) => a.urgency - b.urgency
        },
        {
            title: 'Confidence Score',
            dataIndex: 'confidence',
            key: 'confidence',
            // defaultSortOrder: 'descend',
            sorter: (a, b) => a.confidence - b.confidence
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
            render: text => this.formatDuration(text),
            // defaultSortOrder: 'descend',
            sorter: (a, b) => a.duration - b.duration
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'action',
            render: (_, rec) => (
                <div className='incoming-calls-table-actions'>
                    <Button className='calls-table-action' type='primary' onClick={() => this.viewCallSummary(rec)}>Summary</Button>
                    <Button className='calls-table-action' type='primary' onClick={() => window.open(rec.recordingUrl, '_blank')}>Recording</Button>
                    {/* <button className='calls-table-action' onClick={() => this.delete(rec.id)}>Delete</button> */}
                    {this.props.roleName === 'Tucuxi_Admin' && <Popconfirm
                        title="Delete Call Data"
                        description="Are you sure you want to delete this call?"
                        onConfirm={() => this.onDeleteConfirmed(rec.key)}
                        onCancel={() => console.log('Delete cancelled for call ', rec.key)}
                        okText="Yes"
                        cancelText="No"
                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    >
                        <Button danger>Delete</Button>
                    </Popconfirm>}
                </div>
            )
        },
    ];

    return <Table dataSource={dataSource} columns={columnDefs} />;

  }

  formatSummary(summary) {
    const summaryItems = [];
    if (summary) {
        let nextstart = 0;
        while (nextstart >= 0) {
            const nextStop = summary.indexOf('\n', nextstart + 1);
            if (nextStop > 0) {
                summaryItems.push(<p>{summary.substring(nextstart, nextStop)}</p>);
                nextstart = nextStop;
            } else {
                summaryItems.push(summary.substring(nextstart));
                nextstart = -1;
            }
        }

        return (
            <div className='call-summary'>
                <h4>Summary</h4>
                <div>{summaryItems}</div>
            </div>
        );
    }

    return '';
  }

  render() {
    const call = this.state.callToView;
    return (<>
        <div className='incoming-calls-body'>
            <div className='incoming-calls-table-header'>
                <Tooltip title="Refresh the call list" color='blue' placement='bottomRight'>
                    <Button type="primary" shape="circle" icon={<ReloadOutlined />} size='small' onClick={() => this.getCurrentCallList()} />
                </Tooltip>
                <div className='incoming-calls-table-title'>
                    {this.state.vapiCampaign.assistantName} at {this.state.vapiCampaign.phoneNumber} ({this.state.callList && this.state.callList.length} calls):
                </div>
            </div>
            <div className='incoming-calls-table-wrapper'>
                {this.renderCallTable(this.state.callList)}
            </div>
        </div>
        <div className='incoming-calls-filters'>
            <span className='filter-label'>Showing calls from:</span>
            <DatePicker
                defaultValue={this.state.queryParameters.createdAtGe && dayjs(this.state.queryParameters.createdAtGe)}
                onChange={v => v && this.setState({ queryParameters: { ...this.state.queryParameters, createdAtGe: v.toISOString() } })}
                showTime={true}
            />
            <span className='filter-label'>to:</span>
            <DatePicker
                defaultValue={this.state.queryParameters.createdAtLe && dayjs(this.state.queryParameters.createdAtLe)}
                onChange={v => v && this.setState({ queryParameters: { ...this.state.queryParameters, createdAtLe: v.toISOString() } })}
                showTime={true}
            />
            <span className='filter-label'>limit:</span>
            <InputNumber
                onChange={v => v && this.setState({ queryParameters: { ...this.state.queryParameters, limit: v } })}
                value={this.state.queryParameters.limit}
            />
        </div>
        <Modal
            footer={null}
            title={call && call.id}
            open={call}
            onOk={() => this.setState({ callToView: null })}
            onCancel={() => this.setState({ callToView: null })}
        >
            <p>{call && this.formatSummary(call.summary)}</p>
        </Modal>
    </>);
  }
}

CallDashboard.propTypes = {
    groupName: PropTypes.string.isRequired,
    roleName: PropTypes.string.isRequired,
}