import bland from "./blandConfig.json";

const getCalls = (responseCallback, errorCallback, queryParameters) => {
    const options = {method: 'GET', headers: {authorization: bland.apiKey}};

    let url = bland.baseUrl + 'calls?ascending=false';
    Object.keys(queryParameters).forEach(k => url += ('&' + k + '=' + queryParameters[k]));
    console.log('Using url: ' + url);

    fetch(url, options)
        .then(response => response.json())
        .then(response => responseCallback(response))
        .catch(err => errorCallback(err));
}

export const Bland = {
    getCalls
};