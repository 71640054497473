import cody from "./codyConfig.json";

const getBots = (responseCallback, errorCallback) => {
    get('bots', responseCallback, errorCallback);
}

const getConversations = (responseCallback, errorCallback) => {
    get('conversations', responseCallback, errorCallback);
}

const get = (path, responseCallback, errorCallback, queryParameters) => {
    const options = {method: 'GET', headers: {[cody.authHeader]: cody.authPrefix + cody.apiKey}};

    let url = cody.baseUrl + path + '?a=0';
    Object.keys(queryParameters || {}).forEach(k => url += ('&' + k + '=' + queryParameters[k]));
    console.log('Using url: ' + url);

    fetch(url, options)
        .then(response => response.json())
        .then(response => responseCallback(response))
        .catch(err => errorCallback(err));
}

export const Cody = {
    getBots,
    getConversations
};
