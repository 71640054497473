import { Component } from 'react';
import { Modal, Table } from 'antd';
import TucuxiConfig from './TucuxiConfig.json';
import { Bland } from '../services/Bland'
import './IncomingCalls.css';

export default class IncomingCalls extends Component {

  constructor(props) {
    super(props);

    this.state = {
        count: 0,
        totalCount: 0,
        callList: [],
        callToView: null,
        waitingForResponse: false,
    }
  }

  componentDidMount() {
    setTimeout(() => this.getCurrentCallList(), 1000);
  }

  getCurrentCallList() {
    if (this.state.waitingForResponse) {
        return;
    }

    const queryParameters = {
        // limit: 10,
    };

    this.setState( { waitingForResponse: true },
        () => Bland.getCalls(data => this.handleCallListReceived(data), err => this.handleError(err), queryParameters));
  }

  handleCallListReceived(data) {
    if (data) {
        this.setState({ count: data.count, totalCount: data.total_count, callList: data.calls, waitingForResponse: false }, () =>
            setTimeout(() => this.getCurrentCallList(), TucuxiConfig.bland.callListPollPeriod));
    } else {
        console.log('Response contained no data!');
    }
  }

  handleError(err) {
    console.log(err);
  }

  viewCallDetails(call) {
    this.setState({ callToView: call });
  }

  analyzeCall(call) {
    console.log('analyzeCall: ', call);
  }

  formatDate(dateStr) {
    const date = new Date(dateStr);
    return date.toLocaleString();
  }

  formatDuration(duration) {
    const minutes = Math.floor(duration);
    const seconds = Math.floor((duration - minutes) * 60);
    return (minutes ? (minutes < 10 ? '0' : '') + minutes : '00') + ':' + (seconds ? (seconds < 10 ? '0' : '') + seconds : '00');
  }

  renderCallTable(data) {
    const dataSource = [];
    
    data.forEach(c => {
        const city = c.variables && c.variables.city;
        const state = c.variables && c.variables.state;
        const country = c.variables && c.variables.country;
        dataSource.push({
            key: c.c_id,
            date: c.created_at,
            to: c.to,
            from: c.from,
            duration: c.call_length,
            summary: c.summary,
            city,
            state,
            country,
            location: city && state ? `${city}, ${state}` : country || '',
            zip: c.variables && c.variables.zip,
            language: c.variables && c.variables.language,
            recordingUrl: c.recording_url,
            endedBy: c.call_ended_by,
            status: c.status,
        });
    });

    const columnDefs = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: text => this.formatDate(text),
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
            render: text => this.formatDuration(text),
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'action',
            render: (_, rec) => (
                <div className='incoming-calls-table-actions'>
                    <button className='calls-table-action' onClick={() => this.viewCallDetails(rec)}>View</button>
                    <button className='calls-table-action' onClick={() => this.analyzeCall(rec)}>Analyze</button>
                </div>
            )
        },
    ];

    return <Table dataSource={dataSource} columns={columnDefs} />;

  }

  render() {
    const call = this.state.callToView;
    return (<>
        <div className='tucuxi-app-header'>
            <a href='/'>
                <img 
                    id='tucuxi-app-header-logo'
                    src='images/cropped-tucuxi_color_logo-2-transparent-300x133.png'
                    alt='tucuxi.ai logo'
                />
            </a>
        </div>
        <div className='incoming-calls-body'>
            <div className='incoming-calls-table-title'>
                Incoming Calls ({this.state.totalCount} total):
            </div>
            <div className='incoming-calls-table-wrapper'>
                {this.renderCallTable(this.state.callList)}
            </div>
        </div>
        <Modal
            footer={null}
            title={call && call.c_id}
            open={call}
            onOk={() => this.setState({ callToView: null })}
            onCancel={() => this.setState({ callToView: null })}
        >
            <p>{call && call.summary}</p>
        </Modal>
    </>);
  }
}
